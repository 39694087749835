import { useEffect } from 'react';
import { navigate } from "@reach/router";

const IndexPage = () => {
    useEffect(() => {
        navigate('notes/pages/Home');
    }, []);
    return null;
};

export default IndexPage;